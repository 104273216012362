<template>
  <v-navigation-drawer :value="isDrawerOpen" app elevation="4" width="260" class="app-navigation-menu"
    :right="$vuetify.rtl" @input="val => $emit('update:is-drawer-open', val)">
    <!-- Navigation Header -->
    <div class="vertical-nav-header d-flex items-center ps-6 pe-5 pt-3 pb-2 ">
      <router-link to="/" class="d-flex text-center align-center text-decoration-none">
        <v-slide-x-transition>
          <v-img :src="require(`@/assets/images/logos/logo.png`)" max-width="150px" alt="logo" contain eager
            class="app-logo me-3"></v-img>
        </v-slide-x-transition>
      </router-link>
    </div>

    <!-- Navigation Items -->
    <div v-if="$store.state.isSavings">
      <v-list expand shaped class="vertical-nav-menu-items pr-2">
        <nav-menu-link title="Dashboard" :to="{ name: 'savings.dashboard' }" icon="gauge"></nav-menu-link>
        <nav-menu-link title="Deposit" :to="{ name: 'savings.make_deposits' }" icon="circle-dollar-to-slot"></nav-menu-link>
        <nav-menu-link title="Withdrawal" :to="{ name: 'savings.make_withdrawals' }" icon="cash-register"></nav-menu-link>
        <nav-menu-link title="Clients" :to="{ name: 'savings.clients' }" icon="users"></nav-menu-link>
        <nav-menu-link title="Transactions" :to="{ name: 'savings.my_transactions' }" icon="money-bill-transfer"></nav-menu-link>
        <nav-menu-link title="My Faults" :to="{ name: 'savings.my_faults' }" icon="file-invoice-dollar"></nav-menu-link>

        <nav-menu-group title="Marketing" icon="chart-column">
          <nav-menu-link title="Resources" :to="{ name: 'savings.marketing_resources' }"></nav-menu-link>
          <nav-menu-link title="Prospects" :to="{ name: 'savings.marketing_prospects' }"></nav-menu-link>
        </nav-menu-group>
        <nav-menu-link title="Contact Us" :to="{ name: 'savings.contact_us' }" icon="comments"></nav-menu-link>
      </v-list>
    </div>
    <div v-if="$store.state.isMarketPlace">
      <v-list expand shaped class="vertical-nav-menu-items pr-2">
        <nav-menu-link title="Dashboard" :to="{ name: 'marketplace.dashboard' }" icon="gauge"></nav-menu-link>
        <nav-menu-link title="Requested Orders" :to="{ name: 'marketplace.orders' }" icon="shopping-basket"></nav-menu-link>
        <nav-menu-link title="My Deliveries" :to="{ name: 'marketplace.transactions' }" icon="shopping-cart"></nav-menu-link>
        <nav-menu-link title="My Faults" :to="{ name: 'marketplace.my_faults' }" icon="file-invoice-dollar"></nav-menu-link>

        <nav-menu-group title="Marketing" icon="chart-column">
          <nav-menu-link title="Resources" :to="{ name: 'marketplace.marketing_resources' }"></nav-menu-link>
          <nav-menu-link title="Prospects" :to="{ name: 'marketplace.marketing_prospects' }"></nav-menu-link>
        </nav-menu-group>
        <nav-menu-link title="Contact Us" :to="{ name: 'marketplace.contact_us' }" icon="comments"></nav-menu-link>
      </v-list>
    </div>
    <div v-if="$store.state.isNjangi">
      <v-list expand shaped class="vertical-nav-menu-items pr-2">
        <nav-menu-link title="Dashboard" :to="{ name: 'njangi.dashboard' }" icon="gauge"></nav-menu-link>
        <nav-menu-link title="Njangi Groups" :to="{ name: 'njangi.njangilist' }" icon="users"></nav-menu-link>
        <nav-menu-link title="Event Groups" :to="{ name: 'njangi.eventlist' }" icon="calendar-alt"></nav-menu-link>
        <nav-menu-link title="Deposits" :to="{ name: 'njangi.make_deposits' }" icon="circle-dollar-to-slot"></nav-menu-link>
        <nav-menu-link title="Withdrawals" :to="{ name: 'njangi.make_withdrawals' }" icon="cash-register"></nav-menu-link>
        <nav-menu-link title="My Faults" :to="{ name: 'njangi.my_faults' }" icon="file-invoice-dollar"></nav-menu-link>

        <nav-menu-group title="Marketing" icon="chart-column">
          <nav-menu-link title="Resources" :to="{ name: 'njangi.marketing_resources' }"></nav-menu-link>
          <nav-menu-link title="Prospects" :to="{ name: 'njangi.marketing_prospects' }"></nav-menu-link>
        </nav-menu-group>
        <nav-menu-link title="Contact Us" :to="{ name: 'njangi.contact_us' }" icon="comments"></nav-menu-link>
      </v-list>
    </div>
    <div v-if="$store.state.isFundraising">
      <v-list expand shaped class="vertical-nav-menu-items pr-2">
        <nav-menu-link title="Dashboard" :to="{ name: 'njangi.dashboard' }" icon="gauge"></nav-menu-link>
        <nav-menu-link title="Projects" :to="{ name: 'njangi.njangilist' }" icon="project-diagram"></nav-menu-link>
        <nav-menu-link title="Events" :to="{ name: 'njangi.eventlist' }" icon="calendar-alt"></nav-menu-link>
        <nav-menu-link title="Transactions" :to="{ name: 'njangi.transactions' }" icon="money-bill-transfer"></nav-menu-link>
        <nav-menu-link title="My Faults" :to="{ name: 'njangi.my_faults' }" icon="file-invoice-dollar"></nav-menu-link>
        <nav-menu-link title="Deposits" :to="{ name: 'njangi.deposits' }" icon="circle-dollar-to-slot"></nav-menu-link>
        <nav-menu-link title="Withdrawals" :to="{ name: 'njangi.requested_withdrawals' }" icon="cash-register"></nav-menu-link>

        <nav-menu-group title="Marketing" icon="chart-column">
          <nav-menu-link title="Resources" :to="{ name: 'njangi.marketing_resources' }"></nav-menu-link>
          <nav-menu-link title="Prospects" :to="{ name: 'njangi.marketing_prospects' }"></nav-menu-link>
        </nav-menu-group>
        <nav-menu-link title="Contact Us" :to="{ name: 'njangi.contact_us' }" icon="comments"></nav-menu-link>
      </v-list>
    </div>
    <div v-if="$store.state.isMonitor">
      <v-list expand shaped class="vertical-nav-menu-items pr-2">
        <nav-menu-link title="Dashboard" :to="{ name: 'njangi.dashboard' }" icon="gauge"></nav-menu-link>
        <nav-menu-link title="Users" :to="{ name: 'njangi.deposits' }" icon="users"></nav-menu-link>
        <nav-menu-link title="My Follow Ups" :to="{ name: 'njangi.requested_withdrawals' }" icon="money-bill-transfer"></nav-menu-link>

        <nav-menu-group title="Marketing" icon="chart-column">
          <nav-menu-link title="Resources" :to="{ name: 'njangi.marketing_resources' }"></nav-menu-link>
          <nav-menu-link title="Prospects" :to="{ name: 'njangi.marketing_prospects' }"></nav-menu-link>
        </nav-menu-group>
        <nav-menu-link title="Contact Us" :to="{ name: 'njangi.contact_us' }" icon="comments"></nav-menu-link>
      </v-list>
    </div>
  </v-navigation-drawer>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import NavMenuLink from './components/NavMenuLink.vue'
import NavMenuGroup from './components/NavMenuGroup.vue'
import axios from 'axios'
import store from "../../../store";
import { computed, onMounted } from '@vue/composition-api';

export default {
  components: {
    NavMenuLink,
    NavMenuGroup,
  },
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
  },
  setup() {
    return {
      mini: true,
    }
  },
}
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo {
  transition: all 0.18s ease-in-out;

  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}

@include theme(app-navigation-menu) using ($material) {
  background-color: map-deep-get($material, 'background');
}

.app-navigation-menu {
  .v-list-item {
    &.vertical-nav-menu-link {
      ::v-deep .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
}
</style>
