import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import axios from 'axios'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

library.add(fas)
Vue.component('font-awesome-icon', FontAwesomeIcon) // vue component font awesome
Vue.config.productionTip = false

axios.defaults.baseURL = process.env.VUE_APP_API_URL || "http://127.0.0.1:8000"
console.log(process.env.VUE_APP_API_URL)

var filter = function (text, length, clamp) {
  clamp = clamp || '...';
  var node = document.createElement('div');
  node.innerHTML = text;
  var content = node.textContent;
  return content.length > length ? content.slice(0, length) + clamp : content;
}


Vue.filter('truncate', filter);  // creating filters in vue for truncating text

new Vue({
  router,
  axios,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
