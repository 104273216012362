import Vue from 'vue'
import VueRouter from 'vue-router'
import axios from 'axios'

import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'login',
    meta: {
      requireLogin: false
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/authentication/Login.vue'),
    meta: {
      layout: 'blank',
      requireLogin: false
    },
  },
  {
    path: '/request_new_password',
    name: 'request_new_password',
    component: () => import('@/views/authentication/RequestNewPassword.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/savings/dashboard',
    name: 'savings.dashboard',
    component: () => import('@/views/savings/dashboard/Dashboard.vue'), meta: {
      requireLogin: true
    }
  },
  {
    path: '/marketplace/dashboard',
    name: 'marketplace.dashboard',
    component: () => import('@/views/marketplace/dashboard/Dashboard.vue'), meta: {
      requireLogin: true
    }
  },
  {
    path: '/njangi/dashboard',
    name: 'njangi.dashboard',
    component: () => import('@/views/njangi/dashboard/Dashboard.vue'), meta: {
      requireLogin: true
    }
  },
  {
    path: '/fundraising/dashboard',
    name: 'fundraising.dashboard',
    component: () => import('@/views/fundraising/dashboard/Dashboard.vue'), meta: {
      requireLogin: true
    }
  },
  {
    path: '/monitor/dashboard',
    name: 'monitor.dashboard',
    component: () => import('@/views/monitor/dashboard/Dashboard.vue'), meta: {
      requireLogin: true
    }
  },
  {
    path: '/savings/transactions',
    name: 'savings.my_transactions',
    component: () => import('@/views/savings/transactions/TransactionsList.vue'), meta: {
      requireLogin: true
    }
  },
  {
    path: '/savings/profits_commisions',
    name: 'savings.profits_commisions',
    component: () => import('@/views/savings/profits/ProfitsList.vue'), meta: {
      requireLogin: true
    }
  },
  {
    path: '/savings/my_faults',  // profits and commisions made by topup for the company through njangi
    name: 'savings.my_faults',
    component: () => import('@/views/savings/profits/MyFaults.vue'), meta: {
      requireLogin: true
    }
  },
  {
    path: '/savings/make_deposits',
    name: 'savings.make_deposits',
    component: () => import('@/views/savings/deposit/MakeDeposit.vue'), meta: {
      requireLogin: true
    }
  },

  {
    path: '/savings/make_withdrawals',
    name: 'savings.make_withdrawals',
    component: () => import('@/views/savings/withdrawal/MakeWithdrawals.vue'), meta: {
      requireLogin: true
    }
  },
  {
    path: '/savings/marketing/traffic',
    name: 'savings.marketing_traffic',
    component: () => import('@/views/savings/marketing_traffic/Traffic.vue'), meta: {
      requireLogin: true
    }
  },
  {
    path: '/savings/marketing/prospects',
    name: 'savings.marketing_prospects',
    component: () => import('@/views/savings/marketing_prospects/Prospects.vue'), meta: {
      requireLogin: true
    }
  },
  {
    path: '/savings/marketing/view/prospects/:id',
    name: 'savings.marketing_view_prospects:id',
    component: () => import('@/views/savings/marketing_prospects/ProspectsDetails.vue'), meta: {
      requireLogin: true
    }
  },
  {
    path: '/savings/marketing/add/prospects',
    name: 'savings.add_marketing_prospects',
    component: () => import('@/views/savings/marketing_prospects/AddProspects.vue'), meta: {
      requireLogin: true
    }
  },
  {
    path: '/savings/marketing/add/messages',
    name: 'savings.add_marketing_messages',
    component: () => import('@/views/savings/marketing_prospects/AddMessage.vue'), meta: {
      requireLogin: true
    }
  },
  {
    path: '/savings/marketing/edit/messages/:id',
    name: 'savings.edit_marketing_messages:id',
    component: () => import('@/views/savings/marketing_prospects/EditMessage.vue'), meta: {
      requireLogin: true
    }
  },
  {
    path: '/savings/marketing/send/ad_messages/:group_id/:message_id/:message_means/:start_date/:end_date',
    name: 'savings.edit_marketing_messages.:group_id.:message_id.:message_means.:start_date.:end_date',
    component: () => import('@/views/savings/marketing_prospects/SendAdMessageList.vue'), meta: {
      requireLogin: true
    }
  },
  {
    path: '/savings/marketing/resources',
    name: 'savings.marketing_resources',
    component: () => import('@/views/savings/marketing_resources/Resources.vue'), meta: {
      requireLogin: true
    }
  },
  {
    path: '/savings/marketing/add/resources/:key',
    name: 'savings.add_marketing_resources:key',
    component: () => import('@/views/savings/marketing_resources/AddResource.vue'), meta: {
      requireLogin: true
    }
  },
  {
    path: '/savings/contact_us',
    name: 'savings.contact_us',
    component: () => import('@/views/savings/contact_us/ContactUs.vue'), meta: {
      requireLogin: true
    }
  },
  {
    path: '/savings/contact_us/reply/:id',
    name: 'savings.contact_us_reply:id',
    component: () => import('@/views/savings/contact_us/ContactUsChatReply.vue'), meta: {
      requireLogin: true
    }
  },
  {
    path: '/savings/clients',
    name: 'savings.clients',
    component: () => import('@/views/savings/clients/ClientList.vue'), meta: {
      requireLogin: true
    }
  },

  {
    path: '/marketplace/orders',  // will contain all available orders and the topup can choose a confirmed order from the manager and then he delivers them
    name: 'marketplace.orders',  // orders can also be paid here and it automatically becomes ur order
    component: () => import('@/views/marketplace/orders/OrdersList.vue'), meta: {
      requireLogin: true
    }
  },
  {
    path: '/marketplace/transactions',  // view transactions (orders) only from his deliveries completed, pending delivery, cancelled by him
    name: 'marketplace.transactions',
    component: () => import('@/views/marketplace/transactions/TransactionsList.vue'), meta: {
      requireLogin: true
    }
  },
  {
    path: '/marketplace/profits_commisions',  // view profits from his deliveries
    name: 'marketplace.profits_commisions',
    component: () => import('@/views/marketplace/profits/ProfitsList.vue'), meta: {
      requireLogin: true
    }
  },
  {
    path: '/marketplace/my_faults',  // profits and commisions made by topup for the company through njangi
    name: 'marketplace.my_faults',
    component: () => import('@/views/marketplace/profits/MyFaults.vue'), meta: {
      requireLogin: true
    }
  },
  {
    path: '/marketplace/withdrawals/new',
    name: 'marketplace.make_withdrawals',
    component: () => import('@/views/marketplace/withdrawal/MakeWithdrawal.vue'), meta: {
      requireLogin: true
    }
  },
  {
    path: '/marketplace/marketing/traffic',
    name: 'marketplace.marketing_traffic',
    component: () => import('@/views/marketplace/marketing_traffic/Traffic.vue'), meta: {
      requireLogin: true
    }
  },
  {
    path: '/marketplace/marketing/prospects',
    name: 'marketplace.marketing_prospects',
    component: () => import('@/views/marketplace/marketing_prospects/Prospects.vue'), meta: {
      requireLogin: true
    }
  },
  {
    path: '/marketplace/marketing/resources',
    name: 'marketplace.marketing_resources',
    component: () => import('@/views/marketplace/marketing_resources/Resources.vue'), meta: {
      requireLogin: true
    }
  },
  {
    path: '/marketplace/contact_us',
    name: 'marketplace.contact_us',
    component: () => import('@/views/marketplace/contact_us/ContactUs.vue'), meta: {
      requireLogin: true
    }
  },

  {
    path: '/njangi/njangilist',
    name: 'njangi.njangilist',
    component: () => import('@/views/njangi/njangi_groups/NjangiList.vue'), meta: {
      requireLogin: true
    }
  },
  {
    path: '/njangi/eventlist',
    name: 'njangi.eventlist',
    component: () => import('@/views/njangi/event_groups/EventList.vue'), meta: {
      requireLogin: true
    }
  },
  {
    path: '/njangi/transactions',  // transactions carried out by the topup note that client transactions will be viewed inside his or her njangi
    name: 'njangi.transactions',
    component: () => import('@/views/njangi/transactions/TransactionList.vue'), meta: {
      requireLogin: true
    }
  },
  {
    path: '/njangi/profits_commisions',  // profits and commisions made by topup for the company through njangi
    name: 'njangi.profits_commisions',
    component: () => import('@/views/njangi/profits/ProfitsList.vue'), meta: {
      requireLogin: true
    }
  },
  {
    path: '/njangi/my_faults',  // profits and commisions made by topup for the company through njangi
    name: 'njangi.my_faults',
    component: () => import('@/views/njangi/profits/MyFaults.vue'), meta: {
      requireLogin: true
    }
  },
  {
    path: '/njangi/make_deposits',  // this will be for people that come to pettycash to deposit for their njangi
    name: 'njangi.make_deposits',
    component: () => import('@/views/njangi/deposit/MakeDeposit.vue'), meta: {
      requireLogin: true
    }
  },
  {
    path: '/njangi/withdrawals/new',
    name: 'njangi.make_withdrawals',
    component: () => import('@/views/njangi/withdrawal/MakeWithdrawal.vue'), meta: {
      requireLogin: true
    }
  },
  {
    path: '/njangi/marketing/traffic',
    name: 'njangi.marketing_traffic',
    component: () => import('@/views/njangi/marketing_traffic/Traffic.vue'), meta: {
      requireLogin: true
    }
  },
  {
    path: '/njangi/marketing/prospects',
    name: 'njangi.marketing_prospects',
    component: () => import('@/views/njangi/marketing_prospects/Prospects.vue'), meta: {
      requireLogin: true
    }
  },
  {
    path: '/njangi/marketing/resources',
    name: 'njangi.marketing_resources',
    component: () => import('@/views/njangi/marketing_resources/Resources.vue'), meta: {
      requireLogin: true
    }
  },
  {
    path: '/njangi/contact_us',
    name: 'njangi.contact_us',
    component: () => import('@/views/njangi/contact_us/ContactUs.vue'), meta: {
      requireLogin: true
    }
  },

  {
    path: '/profile/account-settings',
    name: 'profile-account-settings',
    component: () => import('@/views/authentication/account-settings/AccountSettings.vue'), meta: {
      requireLogin: true
    }
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})



router.beforeEach((to, from, next) => {
  // to initialise authenticated
  if (to.matched.some(record => record.meta.requireLogin) && !store.state.isAuthenticated) {
    store.commit('initialiseStore')
  }
  
  if (to.matched.some(record => record.meta.requireLogin) && !store.state.isAuthenticated) {

    next({ name: 'login', query: { to: to.path } });
    store.commit('setSnackBarMessage', "Login is required: Please log into your account or request for the creation of one now.")
    store.commit('setSnackBarColor', "red darken-4")
    store.commit('activateSnackBar', true)
  } else {
    const token = store.state.token
    if (token) {
      axios.defaults.headers.common['Authorization'] = "Token " + token
    } else {
      axios.defaults.headers.common['Authorization'] = ""
    }
    console.log(to.path, from.path)
    next()
  }
})



export default router
