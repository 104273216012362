<template>
  <v-menu
    offset-y
    left
    nudge-bottom="14"
    min-width="300"
    max-width="500"
    content-class="user-notification-menu-content"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        top
        color="error"
        overlap
        offset-x="12"
        offset-y="12"
        class="ms-4"
        content="6"
      >
        <v-btn
          icon
          small
          class="ms-3"
          size="40px"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>
            {{ icons.mdiBellOutline }}
          </v-icon>
        </v-btn>
      </v-badge>
    </template>
    <v-list>
      <div class="pb-3 pt-0">
        <div
          class="d-inline-flex flex-column justify-center ms-3"
          style="vertical-align:middle"
        >
          <span class="text--primary font-weight-semibold mb-n1">
            Notifications
          </span>
        </div>
      </div>

      <v-divider></v-divider>

      <v-list-item link >
        <v-list-item-icon class="me-2 ">
          <v-avatar size="40px">
            <v-img :src="require('@/assets/images/avatars/1.png')"></v-img>
          </v-avatar>
        </v-list-item-icon>
        <v-list-item-content>
            <v-list-item-title class="mt-3 text-body-2" v-html="'<b>Brunch this weekend?</b>'"></v-list-item-title>
            <v-list-item-subtitle class="text-caption" v-html="'<span class=\'text--primary\'>Ali Connors</span> &mdash; I\'ll be in your neighborhood doing errands this weekend. Do you want to hang out?'"></v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mt-3 mb-2"></v-divider>
      <v-list-item link >
        <v-list-item-icon class="me-2 ">
          <v-avatar size="40px">
            <v-img :src="require('@/assets/images/avatars/1.png')"></v-img>
          </v-avatar>
        </v-list-item-icon>
        <v-list-item-content class="">
            <v-list-item-title class="mt-3 text-body-2" v-html="'<b>Brunch this weekend?</b>'"></v-list-item-title>
            <v-list-item-subtitle class="text-caption" v-html="'<span class=\'text--primary\'>Ali Connors</span> &mdash; I\'ll be in your neighborhood doing errands this weekend. Do you want to hang out?'"></v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mb-2 mt-3"></v-divider>
    </v-list>
  </v-menu>
</template>

<script>
import {
  mdiBellOutline,
  mdiAccountOutline,
  mdiEmailOutline,
  mdiCheckboxMarkedOutline,
  mdiChatOutline,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiHelpCircleOutline,
  mdiLogoutVariant,
} from '@mdi/js'

export default {
  setup() {
    return {
      icons: {
        mdiBellOutline,
        mdiAccountOutline,
        mdiEmailOutline,
        mdiCheckboxMarkedOutline,
        mdiChatOutline,
        mdiCogOutline,
        mdiCurrencyUsd,
        mdiHelpCircleOutline,
        mdiLogoutVariant,
      },
    }
  },
  data: () => ({
    items: [
      { header: 'Today' },
      {
        avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg',
        title: 'Brunch this weekend?',
        subtitle: '<span class="text--primary">Ali Connors</span> &mdash; I\'ll be in your neighborhood doing errands this weekend. Do you want to hang out?',
      },
      { divider: true, inset: true },
      {
        avatar: 'https://cdn.vuetifyjs.com/images/lists/2.jpg',
        title: 'Summer BBQ <span class="grey--text text--lighten-1">4</span>',
        subtitle: '<span class="text--primary">to Alex, Scott, Jennifer</span> &mdash; Wish I could come, but I\'m out of town this weekend.',
      },
      { divider: true, inset: true },
      {
        avatar: 'https://cdn.vuetifyjs.com/images/lists/3.jpg',
        title: 'Oui oui',
        subtitle: '<span class="text--primary">Sandra Adams</span> &mdash; Do you have Paris recommendations? Have you ever been?',
      },
      { divider: true, inset: true },
      {
        avatar: 'https://cdn.vuetifyjs.com/images/lists/4.jpg',
        title: 'Birthday gift',
        subtitle: '<span class="text--primary">Trevor Hansen</span> &mdash; Have any ideas about what we should get Heidi for her birthday?',
      },
      { divider: true, inset: true },
      {
        avatar: 'https://cdn.vuetifyjs.com/images/lists/5.jpg',
        title: 'Recipe to try',
        subtitle: '<span class="text--primary">Britta Holt</span> &mdash; We should eat this: Grate, Squash, Corn, and tomatillo Tacos.',
      },
    ],
  }),

}
</script>

<style lang="scss">
.user-notification-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
